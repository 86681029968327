@font-face {
  font-family: "Industry";
  src: url("./Industry-Light.eot");
  src: local("Industry Light"), local("Industry-Light"),
    url("./Industry-Light.eot?#iefix") format("embedded-opentype"),
    url("./Industry-Light.woff") format("woff"),
    url("./Industry-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./Industry-Bold.eot");
  src: local("Industry Bold"), local("Industry-Bold"),
    url("./Industry-Bold.eot?#iefix") format("embedded-opentype"),
    url("./Industry-Bold.woff") format("woff"),
    url("./Industry-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Industry-Book";
  src: url("./Industry-Book.eot");
  src: local("Industry Book"), local("Industry-Book"),
    url("./Industry-Book.eot?#iefix") format("embedded-opentype"),
    url("./Industry-Book.woff") format("woff"),
    url("./Industry-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./Industry-Black.eot");
  src: local("Industry Black"), local("Industry-Black"),
    url("./Industry-Black.eot?#iefix") format("embedded-opentype"),
    url("./Industry-Black.woff") format("woff"),
    url("./Industry-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./Industry-Medium.eot");
  src: local("Industry Medium"), local("Industry-Medium"),
    url("./Industry-Medium.eot?#iefix") format("embedded-opentype"),
    url("./Industry-Medium.woff") format("woff"),
    url("./Industry-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Industry-Demi";
  src: url("./Industry-Demi.eot");
  src: local("Industry Demi"), local("Industry-Demi"),
    url("./Industry-Demi.eot?#iefix") format("embedded-opentype"),
    url("./Industry-Demi.woff") format("woff"),
    url("./Industry-Demi.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
