@font-face {
  font-family: "Industry";
  src: url("assets/fonts/Industry-Light.eot");
  src: local("Industry Light"), local("Industry-Light"),
    url("assets/fonts/Industry-Light.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Industry-Light.woff") format("woff"),
    url("assets/fonts/Industry-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("assets/fonts/Montserrat-Bold.ttf");
  src: local("Montserrat-Bold"), local("Montserrat-Bold"),
}

@font-face {
  font-family: "Tungsten-Bold";
  src: url("assets/fonts/Tungsten-Bold.otf");
  src: local("Tungsten-Bold"), local("Tungsten-Bold"),
}

@font-face {
  font-family: "Industry";
  src: url("assets/fonts/Industry-Bold.eot");
  src: local("Industry Bold"), local("Industry-Bold"),
    url("assets/fonts/Industry-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Industry-Bold.woff") format("woff"),
    url("assets/fonts/Industry-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Industry-Book";
  src: url("assets/fonts/Industry-Book.eot");
  src: local("Industry Book"), local("Industry-Book"),
    url("assets/fonts/Industry-Book.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Industry-Book.woff") format("woff"),
    url("assets/fonts/Industry-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("assets/fonts/Industry-Black.eot");
  src: local("Industry Black"), local("Industry-Black"),
    url("assets/fonts/Industry-Black.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Industry-Black.woff") format("woff"),
    url("assets/fonts/Industry-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("assets/fonts/Industry-Medium.eot");
  src: local("Industry Medium"), local("Industry-Medium"),
    url("assets/fonts/Industry-Medium.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Industry-Medium.woff") format("woff"),
    url("assets/fonts/Industry-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Industry-Demi";
  src: url("assets/fonts/Industry-Demi.eot");
  src: local("Industry Demi"), local("Industry-Demi"),
    url("assets/fonts/Industry-Demi.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Industry-Demi.woff") format("woff"),
    url("assets/fonts/Industry-Demi.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Druk Wide Bold";
  src: url("assets/fonts/2206d6cc490084998d531e8c1b2cbb4a.eot"); /* IE9*/
  src: url("assets/fonts/2206d6cc490084998d531e8c1b2cbb4a.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/2206d6cc490084998d531e8c1b2cbb4a.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("assets/fonts/2206d6cc490084998d531e8c1b2cbb4a.woff") format("woff"),
    /* chrome、firefox */
      url("assets/fonts/2206d6cc490084998d531e8c1b2cbb4a.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("assets/fonts/2206d6cc490084998d531e8c1b2cbb4a.svg#Druk Wide Bold")
      format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Anton";
  src: url("assets/fonts/Anton-Regular.ttf") format("truetype");
}
